import SubHeader from "@/components/SubHeader";
import axios from "@/axios";
import Loader from "@shared/loader";
import Empty from "@shared/empty";
const ListModal = () => import("@shared/components/listModal");
import Button from "@shared/components/button"

export default {
    name: "licence-list",
    components: {
        SubHeader,
        Loader,
        Empty,
        ListModal,
        Button
    },
    props: {},
    data: () => ({
        columns: [
            {
                label: "License Name",
                field: "license_pack_name",
                filterOptions: {
                    enabled: true, // enable filter for this column
                    // multi: true,
                    // isLoading: false,
                    // query_key: "name",
                },
            },
            {
                label: "Description",
                field: "description",
                filterOptions: {
                    // enabled: true, // enable filter for this column
                    // multi: true,
                    // isLoading: false,
                    // query_key: "name",
                },
            }, 
            {
                label: "Number of License",
                field: "client_license_count",
                filterOptions: {
                    enabled: true, // enable filter for this column
                    // multi: true,
                    // isLoading: false,
                    // query_key: "name",
                },
            },  
            {
                label: "Alloted License",
                field: "alloted_license_count",
                filterOptions: {
                    enabled: true, // enable filter for this column
                    // multi: true,
                    // isLoading: false,
                    // query_key: "name",
                },
            },
            {
                label: "Balanced License",
                field: "balanced_license_count",
                filterOptions: {
                    enabled: true, // enable filter for this column
                    // multi: true,
                    // isLoading: false,
                    // query_key: "name",
                },
            },
            {
                label: "Assigned Analyst",
                field: "analyst",
            },
            {
                label: "VALIDITY",
                field: "validity",
                filterOptions: {
                    enabled: true, // enable filter for this column
                    // multi: true,
                    // isLoading: false,
                    // query_key: "name",
                },
            },
        ],
        // dummy Data
        rows: [],
        loadingStates: {
            fetchingLicence: false,
            listModal: false,
            submit: false
        },
        licenseClient: [],
        removedLicenseClient: [],
        serchValues: "",
        selectedLicenseId: null

    }),

    computed: {
        getlicenseClients() {
            return this.licenseClient?.filter((el) => el.user_name?.toLowerCase()?.includes(this.serchValues.toLowerCase()));
        },
    },
    async mounted() {
        await this.getLicensePack();
    },
    methods: {
        async getLicensePack() {
            this.loadingStates.fetchingLicence = true;
            this.serchValues = ""
            try {
                let  { data }  = await axios.get("/license-pack/client/get-license-user-list");
                this.rows = data || [];
            } catch (error) {
                console.log(error, "error");
            }
            this.loadingStates.fetchingLicence = false;
        },
        async handleShowModal(row) {
            this.serchValues = ""
            this.selectedLicenseId = row.license_pack_id
            this.$refs["license-analyst-modal"].showListModal();
            this.getLicenseClientData(row.users);
        },
        handleCloseModal() {
            this.serchValues = ""
            this.$refs["license-analyst-modal"].closeModal();
        },
        async getLicenseClientData(users) {
            this.serchValues = ""
            this.loadingStates.listModal = true;
            try {
                this.licenseClient =  users.map(el => ({...el, id:el.user_id})) || []
            } catch (error) {
                console.log(error, "errror");
            }
            this.loadingStates.listModal = false;
        },

        removeAnalyst(user_id){
            let removedUser = this.licenseClient.filter(el => el.id == user_id )
            this.removedLicenseClient?.push(removedUser);
            this.licenseClient = this.licenseClient.filter(el => el.id !== user_id )
        },

        async saveRemovedAnalyst(){
            try {
                if (this.removedLicenseClient?.length > 0) {
                    let user_ids = this.removedLicenseClient.map((el)=>el.id)
                    let payload = {
                        license_pack_id: this.selectedLicenseId,
                        user_ids: user_ids
                    }
                    const { data } = await axios.delete("/license-pack/client/remove-user", { data: payload });
                    this.selectedLicenseId = null
                    this.$toast.success(data.message || "Analyst Removed");
                }
                this.handleCloseModal()
                await this.getLicensePack()
            } catch (error) {
                console.log('error', error)
                if (Array.isArray(error?.response?.data?.detail)) {
                    this.$toast.error(error?.response?.data?.detail?.[0]?.msg || 'Something is went wrong!')
                } 
                else {
                    this.$toast.error(error?.request?.response?.data?.detail|| 'Something is went wrong!')
                }
            }

        },
        handleFilterResult(event) {
            this.serchValues = event;
        },
    },
};
